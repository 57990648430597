import Vue from 'vue'
import Router from 'vue-router'
import Doctor from '../views/Home.vue'
import Analyst from '../views/Biz_Dev_Analyst.vue'
import Clinical from '../views/Clinical_Research_Manager.vue'
import Development from '../views/Development_Program_Manager.vue'
import AllTogether from '../views/AllTogether.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: '/',
      name: 'Doctor',
      component: Doctor,
    },
    {
      path: '/:name',
      name: 'AllTogether',
      component: AllTogether,
    },
  ],

})




