<template>
  <v-app>
    <div>
      <router-view></router-view>
    </div>
  </v-app>
</template>

<script>
import axios from "axios";
import { EventBus } from "./event-bus.js";
import Vue from "vue";
export default {
  name: "App",
  data() {
    return {
    };
  },
  watch: {
  },
  mounted() {
  },
  created() {
  },
  methods: {
  },
};
</script >
<style>
@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"), url(./fonts/Ubuntu/Ubuntu-Regular.ttf) format("truetype");
}
.app {
  font-family: Ubuntu;
}
</style>
