<template>
  <v-main>
    <div class="margin_align"> 
      <v-row wrap style="height:102vh;">
        <loading :active.sync="loading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"></loading> 
        <v-col class="Path-235" col="12" sm="3" md="3" lg="3" xs="3" xl="3">
            <div style="margin:4vh">
              <img src="../assets/images/Knolense_Dark-01.svg" style="width:9vw;">
              <div style="margin-top:8vh">
                <v-row>
                  <v-col col="12" sm="5" md="5" lg="5" xs="5" xl="5">
                    <span class="I-am">I am</span>
                  </v-col>
                </v-row>
                <v-row wrap style="margin-top:-2vh">
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <span>
                      <v-menu offset-y :close-on-content-click="closeOnContentClick">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <span class="bizdev">{{role}}</span>
                            <img src="../assets/images/Group 164.svg" style="margin-left:2vh;width:0.8vw;margin-right:1vh;" >
                          </div>
                        </template>
                        <v-list style="max-height:35vh;overflow-y:scroll;">
                          <v-list-item v-for="(all, key) in all_roles" :key="key" item-value="my"
                            class="dropdownStyle">
                            <v-list-item-title style="text-transform: capitalize;font-size: 15px;font-weight: 300;color: #013d48" @click="change_role(all)">{{ all }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </span>
                  </v-col>
                </v-row>
                <div style="margin-top:2vh">
                  <span class="I-am">focussed on</span>
                </div>
                <div style="margin-top:2vh">
                  <span>
                      <v-menu offset-y :close-on-content-click="closeOnContentClick">
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs" v-on="on">
                            <span class="bizdev">{{disease}}</span>
                            <img src="../assets/images/Group 164.svg" style="margin-left:2vh;width:0.8vw;margin-right:1vh" >
                          </div>
                        </template>
                        <v-list style="max-height:30vh;overflow-y:scroll;">
                          <v-list-item v-for="(alld, keyy) in alldiseases" :key="keyy" item-value="my"
                            class="dropdownStyle">
                            <v-list-item-title style="text-transform: capitalize;font-size: 15px;font-weight: 300;color: #013d48" @click="change_disease(alld)">{{ alld }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                  </span>
                </div>
              </div>
              <div style="margin-top:6vh">
                <p class="roles">The roles, therapeutic areas and associated questions are illustrative. </p>
                <p class="roles">Knolchat, available upon signup, allows natural language based interactions to with our knowledge graph that is constantly updated.  By asking the right questions, with Knolchat, you can get answers to your questions..</p>
                <p class="roles">In addition to Knolchat, modules such as those on Mechanisms of Action, Drug Pipelines, Therapy Landscapes, Biomarkers tracking and Patient response allow you to have an interactive, in depth, real time analysis of the current state and assess how it impacts your enterprise.</p>
              </div>
              <div style="margin-top: 3vh;">
                <v-btn class="signup" @click="goto_main_website()">Sign up for Knolens</v-btn>
              </div>
            </div>
          </v-col>
          <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3" style="max-height:100vh;overflow-y:scroll;">
            <div style="margin-top: 2vh;">
              <span class="Choose-your">Choose your question of interest from the below</span>
            </div>
            <div>
              <v-card :class="dis.selected ? 'Path-939': 'Path-940'" v-for="(dis,key) in display_data" :key="key" @click="show_plot(dis)">
                <span class="Create-a-grouped">{{dis.Question}}</span>
              </v-card>
            </div>
          </v-col>
          <v-col class="third_section" col="12" sm="6" md="6" lg="6" xs="6" xl="6">            
            <v-row wrap style="margin-top: 1.5vh">
              <v-col cols="12" sm="9" md="9" lg="9" xs="9" xl="9">
                <span class="answer">Answer</span>
              </v-col>
              <v-col cols="12" sm="1" md="1" lg="1" xs="1" xl="1" v-for="network in networks" :key="network.network" align="right" style="margin-left: -3vh;">           
                <ShareNetwork
                  :network="network.name"
                  title="Knolens" 
                  description=""
                  :url= "myurl"
                  quote=""
                  hashtags=""
                > 
                  <span @click="share_article(network.name)">
                    <v-avatar
                      size="22"                             
                      :color="network.color"
                    >
                      <i class="icon" :class="network.icon" color="white"></i>
                    </v-avatar>
                  </span>
                </ShareNetwork>                 
              </v-col>             
            </v-row>
            <div style="margin-right:1vh" id="gist">
              <v-card class="Path-946">
                <v-row style="margin: -1vh 1vh 1vh 1vh">
                  <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1">
                    <img src="../assets/images/Group 1095.svg" style="width: 30px">
                  </v-col>
                  <v-col col="12" sm="11" md="11" lg="11" xs="11" xl="11" align="right">
                    <span @click="show_json(footnote)"><i class="fa fa-info-circle" style="color:#03a6ad;font-size: 20px;cursor: pointer;"></i>
                    </span>
                  </v-col>
                </v-row>
                <v-row style="margin-top:-3vh">
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12" style="overflow: scroll;">
                    <div v-if="div64 != ''" style="height:450px;">
                      <span v-html="div64"></span>
                    </div>
                    <div style="margin-left:2vh;margin-top:2vh;">
                      <span class="answer_text">{{display_text}}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            <div style="margin-top:2vh">
              <v-card class="Path-956">
                <p class="The-above">The above response has been generated using KnolChat. KnolChat is a chat based interface into one of the LLMs that powers Knolens and can be your digital assistant in all research.</p>
                <p class="The-above">The analysis can be shared to your team members and the source data downloaded.for offline analysis.  Knolens provides full end-to-end data traceability for all analysis...</p>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <template>
          <div class="text-center">
            <v-dialog
              v-model="intro_modal"
              width="700"
            >
            <v-card class="intomodal_card">
              <v-card-text>
                <v-row style="background-color: #037a80;margin-right: -4vh;margin-left:-4vh">
                  <v-col col="12" sm="11" md="11" lg="11" xs="11" xl="11">
                    <span class="Introducing">Introducing</span>
                    <div style="margin-left: 2vh;"><img src="../assets/images/Knolense.svg" style="width:8vw;"></div>
                    <hr style="border: 0.5px solid #ffffff;margin-left:2vh">
                    <div style="margin-left:2vh">
                      <span class="Your-personalized">Your personalized digital assistant that automates ongoing research, saving you time and money</span>
                    </div>
                  </v-col>
                  <v-col col="12" sm="1" md="1" lg="1" xs="1" xl="1">
                    <v-icon class="pull-right" color="#ffffff" style="margin:2vh;cursor:pointer;float: right;" @click="intro_modal = false">mdi-close</v-icon>
                  </v-col>
                </v-row>
                <v-row wrap>                                       
                  <v-col col="12" sm="12" md="12" lg="12" xs="12" xl="12">
                    <div style="margin-top: 2vh;">
                      <span class="By-using">By using the latest in AI technologies, Knolens  automates the aggregation & analysis of information from multiple global, clinical, scientific and business sources such as FDA, PubMed, & SEC filings. This provides you, a LifeSciences professional, with a personalized intelligent assistant who helps to stay on top of competition, analyze the impact of industry & scientific evolution in real time, and helps you make design and strategy decision.</span>
                    </div>
                    <div style="margin-top: 2vh;">
                      <span class="By-using">Play around to get a feel of the capabilities of Knolens.  Know that it supports 100s of therapeutic areas and has insight relevant for a wide range of roles from business development/competitive intelligence to scientific & clinical research professionals in the LifeSciences & bio-pharmaceutical industries.</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
                  <v-col col="12" sm="6" md="6" lg="6" xs="6" xl="6">
                    <v-btn class="signup" @click="showme()">Show me what Knolens can do!</v-btn>
                  </v-col>
                  <v-col col="12" sm="3" md="3" lg="3" xs="3" xl="3"></v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>
        </div>
      </template> 
      <template>
        <v-dialog v-model="show_json_template" persistent  width="800">
          <v-card style="border-radius: 20px !important;">
            <v-card-text class="text-center" >
              <v-row style="margin-top:2vh">
                <v-col>
                  <span class="pull-left"  style="cursor:pointer;float: left !important">Json File</span>
                </v-col>                            
                <v-col>
                  <v-icon class="pull-right" color="#009ea5" style="cursor:pointer;float: right !important" @click="show_json_template = false">mdi-close</v-icon>
                </v-col>
              </v-row>          
              <v-row style="padding:2vh">
                <div style="overflow-y: scroll;height: 400px;">
                  <tree-view style="text-align: left;font-family: Ubuntu;font-size: 17px;font-weight: 700;" :data="jsonfile_content" :options="{maxDepth: 1,rootObjectKey: 'root',modifiable: false,defaultOpen: false}"></tree-view>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template> 
    </div>
  </v-main> 
</template>
<script>
import axios from "axios";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import postscribe from "postscribe";

export default {
  data() {
    return {
      loading: false,
      fullPage: true,
      intro_modal: false,
      all_roles: ["Doctor","Business Development Analyst","Clinical Research Manager","Development Program Manager"],
      role: "",
      closeOnContentClick: false,
      disease: "Multiple Sclerosis",
      alldiseases: ["Multiple Sclerosis", "Alzheimer's Disease", "Ovarian Cancer"],
      social_dialog: false,
      network_items: false,
      networks: [
        // {
        //   network: "facebook",
        //   name: "Facebook",
        //   icon: "fab fa-facebook-f",
        //   color: "#1877f2",
        // },

        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fab fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fab fa-whatsapp",
          color: "#25d366",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fab fa-twitter",
          color: "#1da1f2",
        },
        // {
        //   network: "email",
        //   name: "Email",
        //   icon: "fa fa-envelope",
        //   color: "#1da1f2",
        // },
      ],
      myurl: "",
      quote_text: '',
      quotetext: '',
      send_msg: '',
      domain_origin: '',
      display_data: [],
      div64: '',
      footnote: '',
      jsonfile_content: {},
      show_json_template: false,
      display_text: ''
    };
  },
  computed: {},
  components: {
    Loading,
  },
  mounted() {
    console.log(this.$route.params.name,"route")
    var role = this.$route.params.name
    if (role.includes('_')){
      var txt1 = role.split("_").join(" "); 
      this.role = txt1
    } else {
      this.role = role
    }
    this.domain_origin = window.location.origin
    this.myurl = this.domain_origin +"/"+this.role
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://cdn.plot.ly/plotly-2.8.3.min.js')
    document.head.appendChild(recaptchaScript)
    this.check_visit()

    var json_data = require('../assets/json/Multiple_Sclerosis.json');
    this.get_display_data(json_data)
  },
  methods: {
    onCancel() {
      console.log('User cancelled the loader.')
    },
    showme() {
      console.log("showme here")
      this.intro_modal = false
    },
    change_role(name) {
      this.closeOnContentClick = true
      var fullname = ""
      if (name.includes(' ')){
        var txt1 = name.split(" ").join("_"); 
        fullname = txt1
      } else {
        fullname = name
      }
      //this.$router.push("/"+name).catch((err) => {})
      var urll = this.domain_origin + "/" + fullname
      window.open(urll, "_blank");
    },
    check_visit() {
      var firstvisit = localStorage.getItem("visit");
        if (firstvisit == null || firstvisit == "" || firstvisit == undefined || firstvisit == "undefined") {
          //console.log("Visiting first time")
          this.intro_modal = true
          var visit_status = "Visited"
          localStorage.setItem("visit", visit_status);
        } else {
          //this.intro_modal = true
          //console.log("Visited multiple times")
        }
    },
    goto_main_website() {     
      window.location = "https://knolens.com/#/";
    },
    change_disease(disease) {
      this.disease = disease
      this.closeOnContentClick = true
      if (this.disease == "Alzheimer's Disease") {
        var json_data = require('../assets/json/Alzheimers.json');
        this.get_display_data(json_data)
      } else if (this.disease == "Multiple Sclerosis") {
        var json_data = require('../assets/json/Multiple_Sclerosis.json');
        this.get_display_data(json_data)
      } else if (this.disease == "Ovarian Cancer") {
        var json_data = require('../assets/json/Ovarian_Cancer.json');
        this.get_display_data(json_data)
      }
    },
    get_display_data(data) {
      this.loading = true
      this.display_data =[]
      console.log("matching")
        // for(var i = 0; i < data.Questions.length; i++) {
        //   this.display_data.push(data.Questions[i])
        // }
        // console.log(this.display_data,"this.display_data")
        // this.show_plot(this.display_data[0])
      if (this.disease.toLowerCase() == data.Indication.toLowerCase()) {
        console.log("matching")
        for(var i = 0; i < data.Questions.length; i++) {
          this.display_data.push(data.Questions[i])
        }
        console.log(this.display_data,"this.display_data")
        this.show_plot(this.display_data[0])
      } else {
        this.loading = false
        this.div64 = ""
        this.display_text = ""
      }     
    },
    show_plot(dis_data) {
      this.div64 = ""
      this.display_text = ""
      if (dis_data.Answer_type == "htmlfile") {
        var scripts = ''
        for (var j = 0; j < this.display_data.length; j++) {
          if (this.display_data[j].Question == dis_data.Question) {
            dis_data.selected = true
            this.display_data[j].selected = true
          } else {
            this.display_data[j].selected = false
          }
        }
        
        scripts = `<script type="text/javascript">
        setTimeout(function(){`
        var double_encode = dis_data.Answer.split('.')[0]
        var plt_data = window.atob(double_encode);

        //console.log(plt_data,"plt_data")

        var obj = JSON.parse(plt_data)

        var plot_data = obj.div64.split('.')[0]
        var div = window.atob(plot_data);
        div = div.replace(/\\"/g, '"')
        div = div.replace(/\\'/g, "'")
        div = div.replace(/\r?\n|\r/g, "\n")

        dis_data.div64 = div
        dis_data.plot_display = true
        this.div64 = dis_data.div64
        this.footnote = dis_data.Footnote

        //console.log(dis_data.div64,"div")
              
        var script_data = obj.script64.split('.')[0]
        var script = window.atob(script_data);

        script = script.replace(/\\"/g, '"')
        script = script.replace(/\\'/g, "'")
        script = script.replace(/\r?\n|\r/g, "\n")
                
        dis_data.script64 = script
        //console.log(dis_data.script64,"script64")

        var str = dis_data.script64.replace('<script type="text/javascript">', "\n")
        var str1 = str.replace("window.PLOTLYENV=window.PLOTLYENV || {};","\n")
        var str2 = str1.replace("<\/script>", "\n")
        scripts += str2

        scripts = scripts + `}, 100);<\/script>`
        //console.log(scripts,"scripts")
        this.loading = false
        postscribe('#gist', scripts)
      } else if (dis_data.Answer_type == "text") {
        this.div64 = ""
        this.display_text = dis_data.Answer
        for (var j = 0; j < this.display_data.length; j++) {
          if (this.display_data[j].Question == dis_data.Question) {
            dis_data.selected = true
            this.display_data[j].selected = true
          } else {
            this.display_data[j].selected = false
          }
        }
      }
    },
    show_json(ftnote) {
      this.jsonfile_content = {}
      if (ftnote !== null && ftnote !== "" && ftnote !== undefined && ftnote !== 'undefined') {
        this.show_json_template =  true
        var obj_foot = window.atob(ftnote);
        this.jsonfile_content = JSON.parse(obj_foot)
        //console.log(this.jsonfile_content,"footnote")
      }
    }
  },
};
</script>
<style  scoped>
  .margin_align {
    overflow-x: hidden;
    overflow-y: auto;
    font-family: Ubuntu;
    height: 100vh;
  }
  .Path-235 {
    padding: 2vh 0vh 2vh 2vh !important;
    background-color: #013d48;
  }
  .I-am {
    margin-top: 0vh;
    font-family: Ubuntu;
    font-size: 17.5px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .bizdev {
    margin-top: 0vh;
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #f16753;
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-thickness: 1px;
  }
  .roles {
    margin-top: 2vh;
    opacity: 0.75;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .signup {
    background-color: #03a6ad !important;
    color: #fff !important;
    font-family: Ubuntu;
    font-size:15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: normal;
    padding:1vh;
  }
  .Choose-your {
    margin: 2vh;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 2.13;
    letter-spacing: normal;
    text-align: left;
    color: #636869;
  }
  .Path-940 {
    margin: 2vh;
    padding: 1vh;
    box-shadow: 0 0.5px 0px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.7px #ffb2a7 !important;
    background-color: #fff;
  }
  .Create-a-grouped {
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #636869 !important;
  }
  .Path-939 {
    margin: 2vh;
    padding: 1vh;
    box-shadow: 0 0.5px 0px 0 rgba(0, 0, 0, 0.16);
    border-left: 4px solid #1abbbc !important;
    border-right:  1px solid #1abbbc !important;
    border-top:  1px solid #1abbbc !important;
    border-bottom:  1px solid #1abbbc !important;
    background-color: #f8ffff;
  }
  .grouped {
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #0f585c;
  }
  .third_section {
    padding: 2vh 2vh 0vh 2vh;
    border-radius: 10px;
    background-color: #f5f7f9;
  }
  .answer {
    margin: 0vh 2vh 2vh 2vh;
    opacity: 0.75;
    font-family: Ubuntu;
    font-size: 17px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.92;
    letter-spacing: normal;
    text-align: left;
    color: #252529;
  }
  .Path-946 {
    margin: 2vh;
    padding: 2vh;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #e2e5e8;
    background-color: #fff;
    border-radius: 7px !important;
  }
  .Path-956 {
    margin: 2vh;
    padding: 1vh;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #6e9b97 !important;
    background-color: #6e9b97 !important;
    color: #fff !important;
  }
  .The-above {
/*    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.16);*/
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .answer_text {
    opacity: 0.91 !important;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: #636869 !important;
  }
  .Introducing {
    margin: 2vh 2vh 2vh 1vh;
    padding: 1vh;
    font-family: Ubuntu;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.78;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .intomodal_card{
    background-color: #037a80 !important;
  }
  .Your-personalized {
    margin: 0vh;
    font-family: Ubuntu;
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .By-using {
    margin: 2vh 2vh 2vh 0vh;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #3c424a;
  }
  .stay-on {
    margin: 2vh 2vh 2vh 0vh;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #f16753;
  }

  .dropdownStyle {
    font-family: Ubuntu;
    font-size: 15px;
    font-weight: 600;
    color: #49575d !important;
    text-transform: capitalize !important;
  }

  .theme--light.v-list-item--active:before,
  .theme--light.v-list-item--active:hover:before,
  .theme--light.v-list-item:focus:before {
    opacity: 0;
    background-color: #ffffff !important;
    color: #ffffff;
  }

  .v-list-item:hover {
    cursor: pointer;
  }

  .tree-view-item-key {
    color: #03a6ad !important;
  }
  


</style>
