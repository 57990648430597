<template>
    <v-main>
      <div>
        <div v-if="show_comp == 1"><Doctor ref="Doctor"></Doctor></div>
        <div v-if="show_comp == 2"><Analyst ref="Analyst"></Analyst></div>
        <div v-if="show_comp == 3"><Clinical ref="Clinical"></Clinical></div>
        <div v-if="show_comp == 4"><Development ref="Development"></Development></div>
      </div>
    </v-main> 
  </template>
  <script>
    import axios from "axios";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import postscribe from "postscribe";
    import Doctor from '../views/Home.vue'
    import Analyst from '../views/Biz_Dev_Analyst.vue'
    import Clinical from '../views/Clinical_Research_Manager.vue'
    import Development from '../views/Development_Program_Manager.vue'
  
    export default {
    data() {
      return {
        loading: false,
        fullPage: true,
        show_comp : 0,
      };
    },
    computed: {},
    components: {
      Loading,
      Doctor,
      Analyst,
      Clinical,
      Development
    },
    mounted() {
      console.log(this.$route.params.name)
      if(this.$route.params.name == "Doctor"){
        this.show_comp = 1
      }else if (this.$route.params.name == "Business_Development_Analyst"){
        this.show_comp = 2
      }else if(this.$route.params.name == "Clinical_Research_Manager"){
        this.show_comp = 3
      }else if(this.$route.params.name == "Development_Program_Manager"){
        this.show_comp = 4
      }else{
        this.show_comp = 1
      }
    },
    methods: {
      
    },
  };
  </script>
  