import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import Vuex from 'vuex'
import "vuetify/dist/vuetify.min.css";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import router from './router'
import Vuebar from 'vuebar';
import sha256 from 'crypto-js/sha256';
import VueSocialSharing from "vue-social-sharing";
import './css/style.css'
import '@fortawesome/fontawesome-free/css/all.css'     
import '@fortawesome/fontawesome-free/js/all.js'
//import GAuth from 'vue-google-oauth2'
import moment from 'moment'
import Loading from 'vue-loading-overlay'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'
import TreeView from "vue-json-tree-view"

Vue.prototype.moment = moment

import axios from 'axios'

axios.defaults.baseURL = ""

Vue.config.productionTip = false

Vue.use(vuetify, {

});

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex)
Vue.use(VueMaterial)
Vue.use(Vuebar);
Vue.use(VueSocialSharing)
Vue.use(TreeView)

new Vue({
  el: "#app",
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

